import { IonRow, IonCol, IonImg, IonLabel, IonBadge } from '@ionic/vue';
export default {
  name: 'InformationsBuilderAndSite',
  components: {
    IonRow,
    IonCol,
    IonImg,
    IonLabel,
    IonBadge
  },
  props: {
    /** the type can take 3 values:
     * - worksite : The mission is a site
     * - start : the mission is the departure point of a transfer or delivery
     * - finish : The mission is the arrival place of a transfer or a delivery
     */
    type: String,
    // Company receiving the services
    builder: String,
    // Name of the site if there is one
    name: String,
    // City of the site if there is one
    city: String,
    // Manually entered location of the site if there is one
    manual: String,
    instructions: String,
    coupon_company: String,
    type_client: String
  }
};