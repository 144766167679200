<template>
  <ion-row class="title-part-padding-form-driver">
    <ion-col size="9" class="row-align-center">
      <ion-label
        class="title-part-form-driver"
        @click="toggleInternalInformation = !toggleInternalInformation"
        >Informations internes</ion-label
      >
    </ion-col>
    <ion-col class="padding-0 justify-content-end">
      <ion-toggle
        v-model="toggleInternalInformation"
        mode="md"
        color="dark"
      ></ion-toggle>
    </ion-col>
  </ion-row>

  <ion-row v-show="toggleInternalInformation" class="mt-10">
    <ion-col size="9" class="row-align-center">
      <ion-label class="fs-12">Notes :</ion-label>
    </ion-col>
  </ion-row>

  <ion-row v-show="toggleInternalInformation">
    <ion-col>
      <ion-textarea
        name="notes"
        class="textarea-form-driver"
        rows="4"
        maxlength="2000"
        @ionChange="onChangeNotes($event.target.value)"
        :value="notes"
        :class="{
          'limit-reached': notes && notes.length >= 2000,
        }"
      ></ion-textarea>
      <div
        v-if="notes"
        class="ion-text-end ion-margin-end"
        :class="notes.length >= 2000 ? 'text-danger' : 'color-green'"
      >
        {{ notes.length }} / 2000
      </div>
    </ion-col>
  </ion-row>

  <attachments
    v-if="canAddAttachments"
    v-show="toggleInternalInformation"
    v-model:attachments="filteredAttachments"
    v-model:waiting-files="waitingFiles"
    v-model:attachments-to-delete="attachmentsToDelete"
    v-model:attachments-to-update="attachmentsToUpdate"
    :type-vue="typeVue"
    :status="status"
    :files-too-big="filesTooBig"
    kind="internal_information"
  />
</template>

<script>
import { IonRow, IonCol, IonLabel, IonToggle, IonTextarea } from '@ionic/vue';
import { mixinFillingAllObject } from './mixins/fillingAllObject';
import Attachments from '@/components/driver/rentalCoupon/components/Attachments';

export default {
  name: 'InternalInformation',
  components: {
    IonRow,
    IonCol,
    IonLabel,
    IonToggle,
    IonTextarea,
    Attachments,
  },
  mixins: [mixinFillingAllObject],
  props: [
    'notes',
    'attachments',
    'typeVue',
    'status',
    'filesTooBig',
    'canAddAttachments',
  ],
  data() {
    return {
      toggleInternalInformation: false,
      waitingFiles: [],
      attachmentsToDelete: [],
      attachmentsToUpdate: {},
    };
  },
  methods: {
    onChangeNotes(value) {
      this.$emit('update:notes', value);
    },

    resetFilesInformation() {
      this.waitingFiles = [];
      this.attachmentsToDelete = [];
      this.attachmentsToUpdate = {};
    },

    getWaitingFiles() {
      return this.waitingFiles;
    },

    getAttachmentsToDelete() {
      return this.attachmentsToDelete;
    },

    getAttachmentsToUpdate() {
      return this.attachmentsToUpdate;
    },
  },

  computed: {
    filteredAttachments: {
      get() {
        if (!this.attachments) return [];

        return this.attachments.filter(a => a.kind === 'internal_information');
      },

      set(newVal) {
        this.$emit('update:attachments', newVal);
      },
    },
  },
};
</script>
