import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_background_header = _resolveComponent("background-header");

  const _component_Date = _resolveComponent("Date");

  const _component_ion_row = _resolveComponent("ion-row");

  const _component_ion_label = _resolveComponent("ion-label");

  const _component_ion_col = _resolveComponent("ion-col");

  const _component_ion_grid = _resolveComponent("ion-grid");

  const _component_ion_card_content = _resolveComponent("ion-card-content");

  const _component_ion_card = _resolveComponent("ion-card");

  return _openBlock(), _createBlock(_component_ion_card, {
    color: "light",
    class: "card-planning-shadow"
  }, {
    default: _withCtx(() => [_createVNode(_component_background_header, {
      type: "absence",
      reason: $props.mission.reason
    }, null, 8, ["reason"]), _createVNode(_component_ion_card_content, {
      class: "padding-card-ion-card-content"
    }, {
      default: _withCtx(() => [_createVNode(_component_ion_grid, null, {
        default: _withCtx(() => [_createVNode(_component_ion_row, null, {
          default: _withCtx(() => [_createVNode(_component_Date, {
            date: $options.date
          }, null, 8, ["date"])]),
          _: 1
        }), $props.mission.instructions ? (_openBlock(), _createBlock(_component_ion_row, {
          key: 0
        }, {
          default: _withCtx(() => [_createVNode(_component_ion_col, null, {
            default: _withCtx(() => [_createVNode(_component_ion_label, {
              class: "text-card-planning"
            }, {
              default: _withCtx(() => [_createTextVNode(_toDisplayString($props.mission.instructions), 1)]),
              _: 1
            })]),
            _: 1
          })]),
          _: 1
        })) : _createCommentVNode("", true)]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  });
}