import { IonGrid, IonRow, IonCol, IonNote } from '@ionic/vue';
import DetailTransfer from '@/components/driver/common/DetailTransfer';
export default {
  name: 'Transfer',
  components: {
    DetailTransfer,
    IonGrid,
    IonRow,
    IonCol,
    IonNote
  },
  props: {
    machines: Array,
    machines_manual: Array,
    tools: Array,
    tools_manual: Array
  }
};