import { IonCol, IonBadge, IonImg } from '@ionic/vue';
export default {
  name: 'DetailTransfer',
  components: {
    IonCol,
    IonBadge,
    IonImg
  },
  props: {
    machines: Array,
    machines_manual: Array,
    tools: Array,
    tools_manual: Array
  }
};