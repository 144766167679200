import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  key: 0,
  class: "align-line-icon-badge"
};
const _hoisted_2 = {
  class: "align-badge-card-planning"
};
const _hoisted_3 = {
  key: 1,
  class: "align-line-icon-badge mt-5"
};
const _hoisted_4 = {
  class: "align-badge-card-planning"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ion_img = _resolveComponent("ion-img");

  const _component_ion_badge = _resolveComponent("ion-badge");

  const _component_ion_col = _resolveComponent("ion-col");

  return _openBlock(), _createBlock(_component_ion_col, {
    class: "detail-transfer-delivery-card-planning"
  }, {
    default: _withCtx(() => [$props.machines || $props.machines_manual ? (_openBlock(), _createBlock("div", _hoisted_1, [_createVNode(_component_ion_img, {
      class: "width-icon-img-30 mr-5",
      src: "/assets/icon/machine.svg"
    }), _createVNode("div", _hoisted_2, [(_openBlock(true), _createBlock(_Fragment, null, _renderList($props.machines, machine => {
      return _openBlock(), _createBlock(_component_ion_badge, {
        color: "tertiary",
        class: "margin-3",
        key: machine.id
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString([machine.name, machine.fleet_number].filter(m => !!m).join(' - ')), 1)]),
        _: 2
      }, 1024);
    }), 128)), (_openBlock(true), _createBlock(_Fragment, null, _renderList($props.machines_manual, machine => {
      return _openBlock(), _createBlock(_component_ion_badge, {
        color: "tertiary",
        class: "margin-3",
        key: machine
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(machine), 1)]),
        _: 2
      }, 1024);
    }), 128))])])) : _createCommentVNode("", true), $props.tools || $props.tools_manual ? (_openBlock(), _createBlock("div", _hoisted_3, [_createVNode(_component_ion_img, {
      class: "width-icon-img-30 mr-5",
      src: "/assets/icon/tool.svg"
    }), _createVNode("div", _hoisted_4, [(_openBlock(true), _createBlock(_Fragment, null, _renderList($props.tools, tool => {
      return _openBlock(), _createBlock(_component_ion_badge, {
        color: "secondary",
        class: "margin-3",
        key: tool.name
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(tool.name), 1)]),
        _: 2
      }, 1024);
    }), 128)), (_openBlock(true), _createBlock(_Fragment, null, _renderList($props.tools_manual, tool => {
      return _openBlock(), _createBlock(_component_ion_badge, {
        color: "secondary",
        class: "margin-3",
        key: tool
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(tool), 1)]),
        _: 2
      }, 1024);
    }), 128))])])) : _createCommentVNode("", true)]),
    _: 1
  });
}