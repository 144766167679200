const dayjs = require('dayjs');

require('dayjs/locale/fr');

dayjs.extend(require('dayjs/plugin/weekOfYear'));
dayjs.extend(require('dayjs/plugin/localizedFormat'));
dayjs.locale('fr');
const WEEK_FORMAT = 'D MMM';

function makeWeekTitle(start, end) {
  const formattedStart = start.format(WEEK_FORMAT);
  const formattedEnd = end.format(WEEK_FORMAT);
  return `S.${start.week()} du ${formattedStart} au ${formattedEnd}`;
}

function makeWeek(someDate) {
  const start = dayjs(someDate).startOf('week');
  const end = start.add(6, 'days');
  const days = [];
  let current = start.clone();

  while (current.isBefore(end)) {
    days.push({
      label: current.format('ddd'),
      date: current.format('YYYY-MM-DD')
    });
    current = current.add(1, 'day');
  } // One last time for sunday


  days.push({
    label: current.format('ddd'),
    date: current.format('YYYY-MM-DD')
  });
  return {
    label: makeWeekTitle(start, end),
    week: start.week(),
    formattedStart: start.format(WEEK_FORMAT),
    formattedEnd: end.format(WEEK_FORMAT),
    days
  };
}

function formatDate(date) {
  return dayjs(date).format('dddd LL');
}

module.exports = {
  makeWeekTitle,
  makeWeek,
  formatDate
};