import { IonRow, IonCol, IonDatetime, IonDatetimeButton, IonItem, IonLabel, IonModal } from '@ionic/vue';

const dayjs = require('dayjs');

require('dayjs/locale/fr');

dayjs.locale('fr');
export default {
  name: 'Date',
  components: {
    IonRow,
    IonCol,
    IonDatetime,
    IonDatetimeButton,
    IonModal,
    IonItem,
    IonLabel
  },
  emits: ['updateDateDependent'],
  props: ['start', 'end', 'startDefault', 'endDefault', 'oldRentalCouponSending'],
  methods: {
    onChangeStart(date) {
      this.$emit('update:start', date);
      this.$emit('updateDateDependent'); // hide start modal

      this.$refs.modalStart.$el.dismiss();
    },

    onChangeEnd(date) {
      this.$emit('update:end', date);
      this.$emit('updateDateDependent'); // hide end modal

      this.$refs.modalEnd.$el.dismiss();
    }

  }
};