import { IonRow, IonCol, IonInput, IonLabel, IonItem } from '@ionic/vue';
export default {
  name: 'ClientDetails',
  components: {
    IonRow,
    IonCol,
    IonInput,
    IonLabel,
    IonItem
  },
  props: ['toggleShow', 'builder_name', 'segmentContact', 'contact_id', 'supervisorsBuilder', 'oldRentalCouponSending', 'contact_name', 'contact_email', 'builder_id', 'worksite_name', 'typeVue', 'status', 'typeMission', 'typeClient', 'coupon_company'],
  methods: {
    onChangeSegmentContact(value) {
      this.$emit('update:segmentContact', value);
      this.updateSegmentContact(value);
    },

    onChangeContactEmail(value) {
      this.$emit('update:contact_email', value);
    },

    onChangeWorksiteName(value) {
      this.$emit('update:worksite_name', value);
    }

  }
};