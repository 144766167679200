import WhiteHeader from '@/components/driver/header/WhiteHeader';
import { IonPage, IonContent, IonIcon, IonItem, IonList, IonLabel } from '@ionic/vue';
import { mailOutline, chatboxEllipsesOutline, callOutline } from 'ionicons/icons';
export default {
  name: 'Help',
  components: {
    WhiteHeader,
    IonPage,
    IonContent,
    IonItem,
    IonList,
    IonIcon,
    IonLabel
  },

  setup() {
    return {
      callOutline,
      chatboxEllipsesOutline,
      mailOutline
    };
  }

};