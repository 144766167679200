import { IonLabel, IonCol, IonRow, IonImg, IonIcon, IonBadge } from '@ionic/vue';
import { addOutline } from 'ionicons/icons';
import utils from '@/services/utils/enginUtils';
export default {
  name: 'EnginAndTools',
  components: {
    IonLabel,
    IonCol,
    IonRow,
    IonImg,
    IonIcon,
    IonBadge
  },
  props: {
    machine_category: String,
    machine_translation: String,
    machine_name: String,
    machine_number: String,
    tools: {},
    voiture_pilote: Boolean
  },

  setup() {
    return {
      addOutline
    };
  },

  methods: {
    getImageForCategory: utils.getImageForCategory
  }
};