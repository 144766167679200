import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "mt-5 mb-10"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ion_label = _resolveComponent("ion-label");

  return _openBlock(), _createBlock("div", _hoisted_1, [_createVNode(_component_ion_label, {
    class: "date-card-planning"
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString($props.date), 1)]),
    _: 1
  })]);
}