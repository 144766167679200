import { IonRow, IonCol, IonInput, IonItem, IonLabel } from '@ionic/vue';
export default {
  components: {
    IonRow,
    IonCol,
    IonInput,
    IonItem,
    IonLabel
  },
  props: ['worksite_reference', 'order_number']
};