import { IonCard, IonCardContent, IonGrid, IonRow, IonCol, IonLabel } from '@ionic/vue';
import { convertDateStartDateEndToLisibleDate } from '@/services/utils/dateUtils';
import Date from '@/components/driver/card/detailCardPlanning/Date';
import BackgroundHeader from '@/components/driver/card/detailCardPlanning/BackgroundHeader';
export default {
  name: 'CardAbsence',
  components: {
    BackgroundHeader,
    Date,
    IonCard,
    IonCardContent,
    IonGrid,
    IonRow,
    IonCol,
    IonLabel
  },
  props: {
    mission: {}
  },
  methods: {
    /**
     * Go to the absence details page
     */
    goToDetail() {
      this.$router.push(`/absence/${this.mission.id}`);
    }

  },
  computed: {
    date() {
      if (this.mission && this.mission.start && this.mission.end) {
        return convertDateStartDateEndToLisibleDate(this.mission.start, this.mission.end);
      }

      return '';
    }

  }
};