import { IonContent, IonPage, IonLabel, IonRefresher, IonRefresherContent } from '@ionic/vue';
import { forceRefreshPlanning, getPlanning } from "@/services/driver/missionsOrders";
import { ref } from 'vue';
import WhiteHeader from "@/components/driver/header/WhiteHeader";
import { futureDate, pastDate } from "@/services/utils/dateUtils";
import ChooseGoodCard from "@/components/driver/planning/ChooseGoodCard";
export default {
  name: 'PlanningChauffeur',
  components: {
    ChooseGoodCard,
    WhiteHeader,
    IonContent,
    IonPage,
    IonLabel,
    IonRefresher,
    IonRefresherContent
  },

  data() {
    return {
      planning: {},
      temporality: 'present',
      missionSelected: false
    };
  },

  setup() {
    const content = ref();
    return {
      content
    };
  },

  // Launch auto-refresh when page gets displayed
  ionViewDidEnter() {
    this.doRefresh();
    this.refreshInterval = setInterval(() => {
      this.doRefresh();
    }, 60 * 1000);
  },

  // Stop auto-refresh when page quits
  ionViewDidLeave() {
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
      this.refreshInterval = null;
    }
  },

  async mounted() {
    this.planning = await getPlanning();
  },

  methods: {
    async doRefresh(event) {
      try {
        this.planning = await forceRefreshPlanning();
      } catch (_unused) {
        console.log('Unable to force refresh planning.');
      }

      if (event && event.target && event.target.complete) {
        event.target.complete();
      }
    },

    /**
     * Return the temporality of a mission
     * @param start Date when start mission
     * @param end Date when end mission
     * @returns {string} past/present/future
     */
    getTemporality(start, end) {
      if (pastDate(end)) {
        return 'past';
      }

      return futureDate(start) ? 'future' : 'present';
    },

    /**
     * Allows to know if a mission can be displayed according to the selected time frame, Also
     * updates the variable for whether a mission or missions are displayed
     * @param missionStart Date when start mission
     * @param missionEnd Date when end mission
     * @returns {boolean} true/false
     */
    showMission(missionStart, missionEnd) {
      if (this.getTemporality(missionStart, missionEnd) === this.temporality) {
        this.missionSelected = true;
        return true;
      }

      return false;
    }

  }
};