import { IonCol, IonImg, IonBadge } from '@ionic/vue';
export default {
  name: 'DetailDelivery',
  components: {
    IonCol,
    IonImg,
    IonBadge
  },
  props: {
    materials: Array,
    rounds: String
  }
};