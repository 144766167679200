import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ion_img = _resolveComponent("ion-img");

  const _component_ion_col = _resolveComponent("ion-col");

  return _openBlock(), _createBlock(_component_ion_col, {
    size: "1",
    class: "align-center"
  }, {
    default: _withCtx(() => [_createVNode(_component_ion_img, {
      class: "arrow-card-planning",
      src: "/assets/icon/arrow-planning.svg"
    })]),
    _: 1
  });
}