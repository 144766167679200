import { IonGrid, IonRow, IonCol, IonNote, IonLabel } from '@ionic/vue';
import RentalCoupon from '@/components/driver/card/RentalCoupon';
export default {
  name: 'ListeRentalCoupon',
  components: {
    RentalCoupon,
    IonGrid,
    IonRow,
    IonCol,
    IonNote,
    IonLabel
  },
  props: {
    rentalCoupons: {},
    typeMission: String
  }
};