import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_CardWorksite = _resolveComponent("CardWorksite");

  const _component_CardAbsence = _resolveComponent("CardAbsence");

  const _component_CardDelivery = _resolveComponent("CardDelivery");

  const _component_CardTransfer = _resolveComponent("CardTransfer");

  return $props.mission.type === 'reservation' ? (_openBlock(), _createBlock(_component_CardWorksite, {
    key: 0,
    mission: $props.mission
  }, null, 8, ["mission"])) : $props.mission.type === 'absence' ? (_openBlock(), _createBlock(_component_CardAbsence, {
    key: 1,
    mission: $props.mission
  }, null, 8, ["mission"])) : $props.mission.type === 'livraison' ? (_openBlock(), _createBlock(_component_CardDelivery, {
    key: 2,
    mission: $props.mission
  }, null, 8, ["mission"])) : $props.mission.type === 'transfert' ? (_openBlock(), _createBlock(_component_CardTransfer, {
    key: 3,
    mission: $props.mission
  }, null, 8, ["mission"])) : _createCommentVNode("", true);
}