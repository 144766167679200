import CardTransfer from '@/components/driver/card/CardTransfer';
import CardDelivery from '@/components/driver/card/CardDelivery';
import CardWorksite from '@/components/driver/card/CardWorksite';
import CardAbsence from '@/components/driver/card/CardAbsence';
export default {
  name: 'ChooseGoodCard',
  components: {
    CardTransfer,
    CardDelivery,
    CardWorksite,
    CardAbsence
  },
  props: ['mission']
};