import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue";
const _hoisted_1 = {
  class: "align-line-icon-badge"
};
const _hoisted_2 = {
  class: "align-badge-card-planning ml-10"
};
const _hoisted_3 = {
  class: "align-line-icon-badge mt-5"
};
const _hoisted_4 = {
  class: "align-badge-card-planning ml-15"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ion_img = _resolveComponent("ion-img");

  const _component_ion_badge = _resolveComponent("ion-badge");

  const _component_ion_col = _resolveComponent("ion-col");

  return _openBlock(), _createBlock(_component_ion_col, {
    class: "detail-transfer-delivery-card-planning"
  }, {
    default: _withCtx(() => [_createVNode("div", _hoisted_1, [_createVNode(_component_ion_img, {
      class: "width-icon-img-30",
      src: "/assets/icon/material.svg"
    }), _createVNode("div", _hoisted_2, [(_openBlock(true), _createBlock(_Fragment, null, _renderList($props.materials, material => {
      return _openBlock(), _createBlock(_component_ion_badge, {
        color: "tertiary",
        class: "margin-3",
        key: material
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(material), 1)]),
        _: 2
      }, 1024);
    }), 128))])]), _createVNode("div", _hoisted_3, [_createVNode(_component_ion_img, {
      class: "width-icon-img-25",
      src: "/assets/icon/rounds.svg"
    }), _createVNode("div", _hoisted_4, [_createVNode(_component_ion_badge, {
      color: "secondary",
      class: "margin-3"
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString($props.rounds), 1)]),
      _: 1
    })])])]),
    _: 1
  });
}