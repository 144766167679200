import { IonCard, IonCardContent, IonGrid, IonRow } from '@ionic/vue';
import { convertDateStartDateEndToLisibleDate } from '@/services/utils/dateUtils';
import { arrowForwardOutline, addOutline } from 'ionicons/icons';
import DetailDelivery from '@/components/driver/common/DetailDelivery';
import Date from '@/components/driver/card/detailCardPlanning/Date';
import DayPart from '@/components/driver/card/detailCardPlanning/DayPart';
import InformationsBuilderAndSite from '@/components/driver/card/detailCardPlanning/InformationsBuilderAndSite';
import ArrowDeliveryAndTransfer from '@/components/driver/card/detailCardPlanning/ArrowDeliveryAndTransfer';
import EnginAndTools from '@/components/driver/card/detailCardPlanning/EnginAndTools';
import BackgroundHeader from '@/components/driver/card/detailCardPlanning/BackgroundHeader';
import Banner from '@/components/driver/card/detailCardPlanning/Banner';
import { orderMixin } from '@/components/driver/card/mixins/makeOrder';
export default {
  name: 'CardDelivery',
  mixins: [orderMixin],
  components: {
    Banner,
    BackgroundHeader,
    EnginAndTools,
    ArrowDeliveryAndTransfer,
    InformationsBuilderAndSite,
    Date,
    DayPart,
    DetailDelivery,
    IonCard,
    IonCardContent,
    IonGrid,
    IonRow
  },
  props: {
    mission: {}
  },

  setup() {
    return {
      arrowForwardOutline,
      addOutline
    };
  },

  computed: {
    date() {
      if (this.mission && this.mission.start && this.mission.end) {
        return convertDateStartDateEndToLisibleDate(this.mission.start, this.mission.end);
      }

      return '';
    }

  }
};