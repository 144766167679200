import { IonGrid, IonRow, IonCol, IonNote, IonLabel } from '@ionic/vue';
export default {
  name: 'Date',
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonNote,
    IonLabel
  },
  props: {
    start: String,
    end: String
  }
};