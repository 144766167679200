import { IonGrid, IonRow, IonCol, IonNote } from '@ionic/vue';
import { refreshOutline } from 'ionicons/icons';
import DetailDelivery from '@/components/driver/common/DetailDelivery';
export default {
  name: 'Delivery',
  components: {
    DetailDelivery,
    IonGrid,
    IonRow,
    IonCol,
    IonNote
  },
  props: {
    materials: Array,
    rounds: String
  },

  setup() {
    return {
      refreshOutline
    };
  }

};