import { IonHeader, IonToolbar, IonTitle, IonButtons, IonBackButton, IonIcon, IonSegment, IonSegmentButton, IonLabel } from '@ionic/vue';
import { arrowBackCircleOutline } from 'ionicons/icons';
export default {
  name: 'WhiteHeader',
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonBackButton,
    IonIcon,
    IonSegment,
    IonSegmentButton,
    IonLabel
  },
  props: {
    backButton: Boolean,
    routeBack: String,
    title: String,
    temporality: String,
    missionSelected: Boolean
  },

  setup() {
    return {
      arrowBackCircleOutline
    };
  },

  methods: {
    /**
     * Set the temporality of segment
     * @param value // New Temporality of segment
     */
    setTemporality(value) {
      this.$emit('update:temporality', value);
      this.$emit('update:missionSelected', false);
    }

  }
};